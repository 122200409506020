import NavbarEnum
    from '../enumerators/navbar.enum'

export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.submenu()
        NavbarComponent.lang()
        NavbarComponent.searchFocus()
    }

    static sticky() {
        let lastScrollTop = 0
        let stateOnTop = true

        if ($('body.home').length > 0) {
            NavbarComponent.changeLogoColor('banner')
        }

        $(window).on('load scroll', function () {
            const offset = 130 // Navbar default height
            const offsetOnScroll = 90 // Navbar height on scroll
            const navbar = $(NavbarEnum.NAVBAR)
            const scrollTop = $(this).scrollTop()
            let hasBanner = false

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                hasBanner = true
            }

            // Hidden on scroll comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $(NavbarEnum.BODY).addClass('header-is-hidden')
            } else {
                $(NavbarEnum.BODY).removeClass('header-is-hidden')
            }

            lastScrollTop = scrollTop

            // On top/on scroll switch
            if (scrollTop > offset && stateOnTop === true) {
                stateOnTop = false
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')

                if (hasBanner === true) {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else if (scrollTop < offsetOnScroll && stateOnTop === false) {
                stateOnTop = true
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')

                if (hasBanner === true) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $(NavbarEnum.LOGO)
        let logoBanner
        let logoClassic

        logoBanner = IRISCollectionCustomer.imageLogoBanner
        logoClassic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }

    static burger() {
        // Open/Close menu
        $(NavbarEnum.BURGER_MENU_TRIGGER).on('click', () => {
            $(NavbarEnum.BODY).toggleClass('menu-open')

            // Change logo on mobile/tablet
            if (window.matchMedia('(max-width: 1024px)').matches) {
                if ($(NavbarEnum.BODY).hasClass('menu-open')) {
                    NavbarComponent.changeLogoColor('classic')
                } else {
                    if ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) {
                        NavbarComponent.changeLogoColor('banner')
                    }
                }
            }

            // Hide ecomode
            $(NavbarEnum.ECOMODE).addClass('eco-bar--hidden')
        })
    }

    static submenu() {
        if (window.matchMedia('(min-width: 1025px)').matches) {
            $('body').prepend('<div class="menu_mask"></div>')
            $('.menu__item.has-children').on('mouseenter', function () {
                if ($('.navbar').hasClass('navbar--ontop') && $('body').hasClass('has-banner')) {
                    NavbarComponent.changeLogoColor('classic')
                }
                $('body').addClass('menu-open')
                $('.menu__item__submenu').removeClass('submenu-open')
                $(this).find('.menu__item__submenu').addClass('submenu-open')
                $('.menu__item').removeClass('current')
                $(this).addClass('current')
            })
            $('.menu__item, .menu__item__submenu__content').on('mouseleave', () => {
                if ($('.navbar').hasClass('navbar--ontop') && $('body').hasClass('has-banner')) {
                    NavbarComponent.changeLogoColor('banner')
                }
                $('.menu__item__submenu').removeClass('submenu-open')
                $('.menu__item').removeClass('current')
                $('body').removeClass('menu-open')
            })
        } else {
            $(
                '.menu__item.has-children .menu__item__link, .menu__item.has-children .menu__item__more',
            ).on('click', function () {
                $(this).parent('.menu__item').toggleClass('active')
                $(this)
                    .parent('.menu__item')
                    .find('.menu__item__submenu')
                    .toggleClass('submenu-open')
                return false
            })
        }
    }

    static lang() {
        const switcher = '[data-trigger="lang-switcher"]'
        const list = '[data-id="lang-switcher-list"]'

        $(switcher).on('click', () => {
            $(list).toggleClass('lang-switch__list--open')
            setTimeout(() => {
                const currentLangCode = $('html').attr('lang')
                $('.lang-switch__current').html(currentLangCode.slice(0, 2))
            }, '1000')
        })

        // Fix current lang
        setTimeout(() => {
            const currentLangCode = $('html').attr('lang')
            $('.lang-switch__current').html(currentLangCode.slice(0, 2))
        }, '1000')
    }

    static searchFocus() {
        if ($(NavbarEnum.SEARCH_MODAL_INPUT).length > 0) {
            $(NavbarEnum.SEARCH_TRIGGER).on('click', () => {
                document.addEventListener('show.hc.modal', () => {
                    $(NavbarEnum.SEARCH_MODAL_INPUT).focus()
                })
            })
        }
    }
}
